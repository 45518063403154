import React, { Component, FC } from 'react';

import styled, { css } from 'styled-components';

import { Align } from '@shared/enums/align';
import { Colors } from '@shared/interfaces/themes';
import { breakpoint } from 'theme';
import { getStyle } from '@shared/markup/functions';

const h2 = css`
  font-weight: bold;
  font-size: 2rem;

  ${breakpoint.m`
    font-size: 3.25rem;
  `}
`;

type TitleProps = {
  className?: string;
  variant?: 'h2';
  text?: string;
  smAlign?: Align;
  mAlign?: Align;
  lgAlign?: Align;
  lineHeight?: number;
  color?: keyof Colors;
};

function Title(props: TitleProps) {
  return (
    <h2 className={props.className} role="heading">
      {props.text}
    </h2>
  );
}

const StyledTitle = styled(Title)`
  margin: 0;

  ${props => props.variant === 'h2' && h2};

  color: ${props =>
    props.theme.colors[props.color] || props.theme.colors.cornflowerBlue};
  line-height: ${props => props.lineHeight || 'normal'};

  ${props => getStyle(props.smAlign, 'text-align')}

  ${breakpoint.m`
    ${props => getStyle(props.mAlign, 'text-align')}
  `}
  
  ${breakpoint.lg`
    ${props => getStyle(props.lgAlign, 'text-align')}
  `}
`;

export default StyledTitle;

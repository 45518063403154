import React, { Component } from 'react';

import styled from 'styled-components';

import Box from '../atoms/Box/Box';
import Button from '../atoms/Button/Button';
import { Col, Row } from '../atoms/Grid';
import Image from '../atoms/Image/Image';
import NewTitle from '../atoms/NewTitle/NewTitle';
import Paragraph from '../atoms/Paragraph/Paragraph';

import { Align } from '@shared/enums/align';
import { breakpoint } from 'theme';

const BoxWrapper = styled(Box)`
  display: flex;
  flex-grow: 1;
  padding: 2em 1em;

  ${breakpoint.m`
    width: 43em;
    margin-left: auto;
    margin-right: auto;
    padding: 4em 0;
  `}

  ${breakpoint.lg`
    width: 76em;
    padding: 5.375em 0;
  `}
`;

const BoxRight = styled(Box)`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type LoginTemplateProps = {
  onLogin: () => void;
  title: string;
  subTitle: string;
  loginBtnText: string;
};

class LoginTemplate extends Component<LoginTemplateProps> {
  render() {
    const { title, subTitle, loginBtnText, onLogin } = this.props;

    return (
      <BoxWrapper>
        <Row gutter={0} middle="lg" width="100%">
          <Col xs={12} lg={7}>
            <Box smMb={2.375} mMb={3.625} lgMb={0}>
              <NewTitle
                variant="h2"
                text={title}
                smAlign={Align.Center}
                lgAlign={Align.Left}
              />
              <Box smMt={1} mb={2} mt={2}>
                <Paragraph smAlign={Align.Center} lgAlign={Align.Left}>
                  {subTitle}
                </Paragraph>
              </Box>
              <Box align={Align.Center} lgAlign={Align.Left} mt={1}>
                <Button onClick={onLogin} variant="tiffHero">
                  {loginBtnText}
                </Button>
              </Box>
            </Box>
          </Col>
          <Col xs={12} lg={5}>
            <BoxRight>
              <Image
                src="https://cdn.3d4medical.com/store/images/discount.svg"
                mWidth={20}
                lgWidth={28}
              />
            </BoxRight>
          </Col>
        </Row>
      </BoxWrapper>
    );
  }
}

export default LoginTemplate;

import React, { FC, PropsWithChildren } from 'react';

import styled from 'styled-components';

import { Align } from '@shared/enums/align';
import { Colors } from '@shared/interfaces/themes';
import { breakpoint } from 'theme';
import { getStyle } from '@shared/markup/functions';

type ParagraphProps = {
  className?: string;
  smAlign?: Align;
  mAlign?: Align;
  lgAlign?: Align;
  lineHeight?: number;
  color?: keyof Colors;
  weight?: string | number;
};

const Paragraph: FC<PropsWithChildren<ParagraphProps>> = ({
  className,
  children,
}) => {
  return (
    <p data-cy="paragraph-test" className={className}>
      {children}
    </p>
  );
};

const StyledParagraph = styled(Paragraph)`
  margin: 0;

  font-weight: ${props => props.weight || 'normal'};
  color: ${props =>
    props.theme.colors[props.color] || props.theme.colors.cornflowerBlue};
  line-height: ${props => props.lineHeight || 1.5};
  font-size: 1.25rem;

  ${props => getStyle(props.smAlign, 'text-align')}

  ${breakpoint.m`
    ${props => getStyle(props.mAlign, 'text-align')}
    font-size: 1.5rem;
  `}
  
  ${breakpoint.lg`
    ${props => getStyle(props.lgAlign, 'text-align')}
  `}
`;

export default StyledParagraph;

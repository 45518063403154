import React, { Component } from 'react';

import { connect } from 'react-redux';

import { AppDispatch } from 'redux-types';
import { _isAuthorized } from '@redux/reducers/auth/selectors';
import { compose } from 'redux';
import { login } from '@redux/reducers/auth/action';

import Layout from '@components/organisms/Layout';
import LoginTemplate from '@components/templates/LoginTemplate';

import { ROUTE } from '@utils/index';
import redirect from '@utils/redirect';

import { HeaderType } from '@shared/markup/constants';
import { I18nTranslation } from '@shared/interfaces/i18n';
import Router from 'next/router';
import { withTranslation } from 'i18n';

type LoginProps = {
  nextUrl?: string;
  titleType?: any;
  dispatch: AppDispatch;
  t: I18nTranslation;
};

class Login extends Component<LoginProps> {
  static async getInitialProps({ req, res, query, store }) {
    const nextUrl = query.next || ROUTE.PRICING;
    const titleType = query.title || '';

    const state = store.getState();
    const isAuthorized = _isAuthorized(state);

    if (isAuthorized) {
      redirect(res, nextUrl);

      return {};
    }

    return {
      nextUrl,
      titleType,
      isAuthorized,
    };
  }

  handleLogin = () => {
    this.props.dispatch(login()).then(() => {
      Router.push(this.props.nextUrl);
    });
  };

  get headers() {
    const { titleType, t } = this.props;
    const knownTypes = ['manage'];

    const key =
      titleType && knownTypes.includes(titleType) ? titleType : 'default';

    return {
      title: t(`header.${key}.title`),
      subTitle: t(`header.${key}.subTitle`),
    };
  }

  render() {
    const { t } = this.props;
    const { title, subTitle } = this.headers;

    return (
      <Layout headerType={HeaderType.Ca}>
        <LoginTemplate
          title={title}
          subTitle={subTitle}
          loginBtnText={t('loginBtn')}
          onLogin={this.handleLogin}
        />
      </Layout>
    );
  }
}

const withConnect = connect(() => ({}));

export default compose<LoginProps>(
  withConnect,
  withTranslation('login')
)(Login);
